@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 0%;
    --foreground: 0 0% 100%;
    --card: 240 5% 4%;
    --card-foreground: 0 0% 100%;
    --popover: 240 5% 4%;
    --popover-foreground: 0 0% 100%;
    --primary: 28 100% 50%;
    --primary-foreground: 0 0% 100%;
    --alternative: 240 100% 50%;
    --alternative-foreground: 0 0% 100%;
    --secondary: 240 4% 14%;
    --secondary-foreground: 0 0% 83%;
    --muted: 240 4% 24%;
    --muted-foreground: 240 5% 65%;
    --accent: 240 4% 14%;
    --accent-foreground: 0 0% 96%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 93% 94%;
    --warning: 49, 96%, 40%;
    --warning-foreground: 49, 100%, 90%;
    --border: 240 4% 14%;
    --input: 0 0% 0%;
    --ring: 28 100% 50%;
    --radius: 0.5rem;
  }

  * {
    @apply border-border focus-visible:outline-none lowercase;
  }

  html {
    @apply font-sans antialiased;
  }

  body {
    @apply bg-background text-foreground overflow-y-auto;
    font-size: 16px;
  }

  .scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  a[href] {
    @apply normal-case;
  }
  button {
    @apply lowercase;
  }

  input,
  code,
  textarea {
    @apply normal-case;
  }
}
